(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

jQuery(function ($) {
  // ショップサーブトップページにだけ読み込ませる
  // swiper
  var topMv = new Swiper('#topMv', {
    pagination: {
      el: '#topMvPagination',
      clickable: true
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
    speed: 2000,
    slidesPerView: 1,
    loopedSlides: 1,
    loop: true,
    on: {
      slideChange: function slideChange() {
        getCurrentNum(topMv, '#topMv');
      }
    }
  });
  createSlideFraction('#topMv');

  function getCurrentNum(swiperName, currentSlideName) {
    var currentNum = 0;
    currentNum = swiperName.realIndex + 1;

    if (currentNum < 10) {
      currentNum = '0' + currentNum;
    } else {
      currentNum;
    }

    $(currentSlideName).find('.original-fraction').find(".__current").html(currentNum);
  }

  function createSlideFraction(currentSlideName) {
    var innerHtml = '';
    var slideNum = $(currentSlideName).find('.swiper-pagination').children('span').length;

    if (slideNum < 10) {
      slideNum = '0' + slideNum;
    } else {
      slideNum;
    }

    innerHtml = '<span class="__current">01</span>';
    innerHtml += '<span class="__total">' + slideNum + '</span>';
    $(currentSlideName).find(".original-fraction").html(innerHtml);
  }

  if ($(window).width() >= 768) {
    var topRecommend = new Swiper('#topRecommend', {
      slidesPerView: 3,
      spaceBetween: 32,
      loop: true,
      navigation: {
        nextEl: '#topRecommendNext',
        prevEl: '#topRecommendPrev'
      }
    });
    var topStandard = new Swiper('#topStandard', {
      slidesPerView: 3,
      spaceBetween: 32,
      loop: true,
      navigation: {
        nextEl: '#topStandardNext',
        prevEl: '#topStandardPrev'
      }
    });
    var topStandard = new Swiper('#topRanking', {
      slidesPerView: 3,
      spaceBetween: 32,
      // loop: true,
      navigation: {
        nextEl: '#topRankingNext',
        prevEl: '#topRankingPrev'
      }
    });
  } // topページ各タイトル下テキスト


  $(function () {
    // ranking
    var rankingText = '<p class="title-bottom-text">ご家庭用、ご贈答用にも人気の商品</p>';
    $('.ranking').find('h2').after(rankingText);
  }); //newsコンテンツのscroll-yを表示させるタイミング

  $(function () {
    newHeightScroll();
    $(window).on('resize', function () {
      newHeightScroll();
    });

    function newHeightScroll() {
      var newsHeight = $('.news .news-contents').innerHeight(); // console.log(newsHeight);

      var newsHeightFlg;

      if ($(window).width() > 768) {
        newsHeightFlg = 235;
      } else {
        newsHeightFlg = 149;
      }

      if (newsHeight >= newsHeightFlg) {
        $(".news-contents").addClass("overflow");
      } else {
        $(".news-contents").removeClass("overflow");
      }
    }
  });
});

},{}]},{},[1]);
